import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { getSiblings } from 'components/tools'
import * as Sentry from "@sentry/browser";
import { trackingEvent } from "../components/tracking_event";
import { formatCurrency } from "../components/exchange_rates";
export default class extends Controller {
  static targets = [
    'addToCartBtn',
    'loginBtn',
    'addedToCartBtn', 
    'purchasedBtn',
    'discountedPrice',
    'price',
    'variantTypePresupported',
    'variantTypeNonsupported',
    'variantTypeSliced',
    'variantPrinterMega8ks',
    'variantPrinterMighty8k',
    'variantPrinterMighty4k',
    'variantPrinterMini8k',
    'variantPrinterMini4k',
    'includeOriginalFile',
    'excludeOriginalFile',
    'variantPhrozenDisclaimer',
    'variantCtbDisclaimer',
  ]
  static classes = ['hidden']
  static values = {
    // post id
    itemId: String,
    variantId: Number,
  }
  postInfo = null
  postVariantInfo = null
  selectedVariantId = null

  // 這邊會跟著 models/post.rb PRINTER_TYPES
  printerTypes = ['Mega8ks','Mighty8k','Mighty4k','Mini8k','Mini4k']

  connect() {
    // init selected variant id
    const selectVariant = document.querySelector('.variant-option.selected')
    if (selectVariant) {
      this.selectedVariantId = selectVariant.dataset.id
    }

    // get post info for ga
    Rails.ajax({
      url: `/api/v1/posts/${this.itemIdValue}`,
      type: "GET",
      success: resp => {
        this.postInfo = resp
        trackingEvent.view_item(this.postInfo.data)
      },
      error: resp => {
        Sentry.captureException(resp);
      }
    });
  }

  addItem(e){
    e.preventDefault()

    if(!this.selectedVariantId) {
      alert('Please choose file format')
      return
    }
    const formData = new FormData()
    formData.append('id', this.selectedVariantId)

    // get post variant info for ga
    Rails.ajax({
      url: `/api/v1/post_variants/${this.selectedVariantId}`,
      type: "GET",
      success: resp => {
        this.postVariantInfo = resp
      },
      error: resp => {
        Sentry.captureException(resp);
      }
    });

    Rails.ajax({
      url: "/cart_items",
      data: formData,
      type: "POST",
      success: resp => {
        if (resp.status === 'ok') {
          // update cart icon number
          let evt = new CustomEvent('addToCart', { 'detail': { 'cart_item_count':  resp.cart_item_count} });
          document.dispatchEvent(evt);

          // 發送 add to cart ga event
          trackingEvent.add_to_cart(this.postVariantInfo.data)

          // change variant incart dataset
          this.setVariantDataset(this.selectedVariantId)

          this.enableAddedToCartBtn()
          this.showHeaderCart()
        }
      },
    });
  }

  setVariantDataset(variantId){
    const variantIdElem = document.querySelector(`[data-id='${this.selectedVariantId}']`)
    variantIdElem.dataset.inCart = 'true'
  }

  enablePurchasedBtn() {
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.add('hidden')
    }
    this.addedToCartBtnTarget.classList.add('hidden')
    this.purchasedBtnTarget.classList.remove('hidden')
  }

  enableAddedToCartBtn(){
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.add('hidden')
    }
    this.addedToCartBtnTarget.classList.remove('hidden')
    this.purchasedBtnTarget.classList.add('hidden')
  }

  enableAddToCartBtn(){
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.remove('hidden')
    }
    this.addedToCartBtnTarget.classList.add('hidden')
    this.purchasedBtnTarget.classList.add('hidden')
  }

  selectVariant(e) {
    const variantElem = e.currentTarget
    const variantId = variantElem.dataset.id
    const variantInCart = variantElem.dataset.inCart
    const variantPurchased = variantElem.dataset.purchased
    const variantDiscountedPrice = parseFloat(variantElem.dataset.discountedPrice)
    const variantPrice = parseFloat(variantElem.dataset.price)

    this.selectedVariantId = variantId

    // change variant statut
    variantElem.classList.add('selected')

    const siblings = getSiblings(variantElem)
    for( const sibling of siblings ) {
      sibling.classList.remove('selected')
    }

    // change add to cart status
    if(!isNaN(variantDiscountedPrice) && !isNaN(variantPrice) ) {
      this.discountedPriceTarget.innerText = formatCurrency(variantDiscountedPrice)

      // origin price
      if(this.hasPriceTarget) {
        if(variantPrice > variantDiscountedPrice) {
          this.priceTarget.innerText = formatCurrency(variantDiscountedPrice)
        } else {
          this.priceTarget.innerText = ''
        }
      }

      // change variant content
      const variantContentFormat = document.querySelector('[data-variant-content = "' + variantId + '"]')
      const contentSiblings = getSiblings(variantContentFormat)
      for( const sibling of contentSiblings ) {
        sibling.classList.add('hidden')
      }
      variantContentFormat.classList.remove('hidden')

      // change variant file type
      if ( variantElem.dataset.includePresupported == 'true' ) {
        this.variantTypePresupportedTarget.style.opacity = 1
      } else {
        this.variantTypePresupportedTarget.style.opacity = 0.5
      }

      if ( variantElem.dataset.includeNonsupported == 'true' ) {
        this.variantTypeNonsupportedTarget.style.opacity = 1
      } else {
        this.variantTypeNonsupportedTarget.style.opacity = 0.5
      }

      if ( variantElem.dataset.includeSliced == 'true' ) {
        this.variantTypeSlicedTarget.style.opacity = 1
      } else {
        this.variantTypeSlicedTarget.style.opacity = 0.5
      }

      // show printer type
      const event = this
      this.printerTypes.forEach(function(printer){
        const printer_target = 'variantPrinter' + printer + 'Target'
        event[printer_target].style.opacity = 0.5
      })
      if ( variantElem.dataset.includeSliced == 'true' ) {
        variantElem.dataset.printerType.split(',').forEach(function(printer){
          const printer_target = 'variantPrinter' + printer + 'Target'
          event[printer_target].style.opacity = 1
        })
      }

      // show original stl file
      if ( variantElem.dataset.includeNonsupported == 'true' ) {
        this.includeOriginalFileTarget.classList.remove('hidden')
        this.excludeOriginalFileTarget.classList.add('hidden')
      } else {
        this.includeOriginalFileTarget.classList.add('hidden')
        this.excludeOriginalFileTarget.classList.remove('hidden')
      }

      // change variant file format
      const variantFileFormat = document.querySelector('[data-variant-file-format = "' + variantId + '"]')
      const fileFormatSiblings = getSiblings(variantFileFormat)
      for( const sibling of fileFormatSiblings ) {
        sibling.classList.add('hidden')
      }
      variantFileFormat.classList.remove('hidden')

      // show disclaimer
      if ( variantElem.dataset.phrozenVarified == 'true' && variantElem.dataset.includePresupported == 'true' ) {
        this.variantPhrozenDisclaimerTarget.classList.remove('hidden')
      } else {
        this.variantPhrozenDisclaimerTarget.classList.add('hidden')
      }

      if ( variantElem.dataset.includeSliced == 'true' ) {
        this.variantCtbDisclaimerTarget.classList.remove('hidden')
      } else {
        this.variantCtbDisclaimerTarget.classList.add('hidden')
      }

      window.contentEllipsis()
    }
    // 假如未登入會有LoginBtnTarget
    if(!this.hasLoginBtnTarget) {
      if(variantPurchased === 'false') {
        if(variantInCart === 'true') {
          this.enableAddedToCartBtn()
        } else {
          this.enableAddToCartBtn()
        }
      } else {
        this.enablePurchasedBtn()
      }
    }
  }

  showHeaderCart(){
    const headerCart = document.querySelector('.header-cart')
    if (headerCart && headerCart.classList.contains('hidden')) {
      headerCart.classList.remove('hidden');
    }
  }
}
