import Dropzone from 'dropzone'
import { queryParent, isIOS } from './tools'
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import i18next from 'i18next';
import * as Sentry from '@sentry/browser';
import { beforeSubmit } from "./required_input_validator"

// 禁止對所有元素的自動查找:
Dropzone.autoDiscover = false

function postFileGroupSettings(groupId) {
  return {
    method: 'PUT',
    url: '/',
    presign_upload_url: `/post_files/presign_upload`,
    paramName: 'attachments[files]',
    maxFiles: 100,
    maxFilesize: 10240, // MB
    parallelUploads: 2,
    file_group_id: groupId,
    fileIDs: []
  }
}

function postImgSettings() {
  return {
    method: 'PUT',
    url: '/',
    presign_upload_url: `/post_imgs/presign_upload`,
    paramName: 'attachments[images]',
    maxFiles: 10,
    maxFilesize: 50, // MB
    parallelUploads: 2,
    acceptedFiles: 'image/jpeg,image/png,image/jpg,image/gif',
    file_group_id: '',
    fileIDs: []
  }
}

function removeLink(fileName, fileId, form) {
  let url = ''
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1)
  if(['jpeg','png','jpg','gif'].includes(fileExtension.toLowerCase())) {
    url = `/post_images/${fileId}`
  } else {
    url = `/post_files/${fileId}`
  }

  fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  })
    .then(response => {
      // 檢查必填欄位
      beforeSubmit(form)
    })
    .catch(error => {
      console.error('Remove image Error:', error);
    });
}

function getUploadLink(dropzone, file, postId, callback) {
  const options = dropzone.options
  const filename = encodeURIComponent(file.name)

  fetch(`${options.presign_upload_url}?filename=${filename}&file_size=${file.size}&file_group_id=${options.file_group_id}&post_id=${postId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  })
    .then(response => response.json())
    .then(res => {
      callback({
        presignedUrl: res.presigned_url,
        id: res.attachment_id
      })
    })
    .catch(error => {
      console.error('GetUploadLink error:', error);
    });
};

function uploadFileSuccess(fileName, fileId, form, callback) {
  let url = ''
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1)
  if(['jpeg','png','jpg','gif'].includes(fileExtension.toLowerCase())) {
    url = `/post_images/${fileId}/upload_successed`
  } else {
    url = `/post_files/${fileId}/upload_successed`
  }

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  })
    .then(response => {
      // 檢查必填欄位
      beforeSubmit(form)

      callback()
    })
    .catch(error => {
      console.error('UploadFileSuccess error:', error);
    });
}

function checkUploadState() {
  const allProgress = document.querySelectorAll("[data-dz-uploadprogress]")
  let uploadSuccess = true

  for(let i = 0; i < allProgress.length; i++) {
    let progressWidth = allProgress[i].style.width

    if (progressWidth == '' || progressWidth == '100%'){
      uploadSuccess = uploadSuccess && true
    } else {
      uploadSuccess = uploadSuccess && false
    }
  }

  if (uploadSuccess) {
    document.body.dataset.dropzoneUploading = false
  }
}

const DROPZONE_SELECTOR = '[data-dropzone]'

// with dropzone_component.html.erb 
class DropZoneClass {
  constructor($dom){
    this.$dom = $dom
    this.$form = queryParent('form')(this.$dom)
    this.type = this.$dom.dataset['dropzone'] // 代表dropzone的type, 如 postFileGroup, postImage
    this.$select = this.$dom.querySelector('select')
    this.hint = this.$dom.parentNode.querySelector('.dropzone-hint')
    this.errorMsg = $(this.$dom.parentNode).siblings('.dropzone-error-msg')[0]
    this.acceptFormat = ''
    this.disabled = this.$dom.dataset.disabled

    if (!this.type) {
      throw 'data-dropzone is no type!'
    }

    if (this.type == 'postFileGroup' ) {
      const group_id = this.$dom.dataset['fileGroupId']
      this.settings = postFileGroupSettings(group_id) || {}

      // from _file_group_fields
      this.acceptFormat = this.$dom.dataset['formats']
      // 注意除了settings.acceptedFiles外，addedfiles 也會檢查檔案格式
      // ios file input accept 有bug當限制多種file input會全部都無法上傳
      if(isIOS()) {
        this.settings.acceptedFiles = ''
      } else {
        this.settings.acceptedFiles = this.acceptFormat
      }
    } else {
      // post img group 限制從settings上取得
      this.settings = postImgSettings() || {}
      this.acceptFormat = this.settings.acceptedFiles
    }

    if (this.$form.action.match(/\/profile\/models\/(\d+)/)){
      this.postId = this.$form.action.match(/\/profile\/models\/(\d+)/)[1]
    }

    this.isEditForm = this._checkEditForm()
    this.init()
  }

  init(){
    this.dropZone = this._createDropZone();
  }

  destroy(){
    this.dropZone.destroy()
  }

  _dropzoneSettings() {
    return {
      autoProcessQueue: true,
      autoQueue: false,
      dictDefaultMessage: '',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      previewTemplate: document.querySelector(".my-template").innerHTML,
      ...this.settings
    }
  }

  _checkEditForm() {
    const formUrl = new URL(this.$form.action)
    return formUrl.pathname.match(/\/\d+(|\/file_groups|\/adv_update)$/)
  }

  _createOption({value, selected}) {
    const option = document.createElement('option')
    option.setAttribute('value', value)
    option.setAttribute('selected', selected)
    return option
  }

  _createSortedInput({self, file}) {
    if (!self.type.match(/image$/i)) {
      return
    }

    const selectName = self.$select.name // ex: post[image_ids][]
    const originParams = selectName.match(/\[.*_ids\]/)[0].slice(1,-1) // ex: image_ids
    const sortedParams = "sorted_" + originParams // ex: sorted_image_ids
    const sortedName = selectName.replace(originParams, sortedParams)

    const checkbox = document.createElement('input')
    checkbox.setAttribute('type', 'checkbox')
    checkbox.setAttribute('name', sortedName)
    checkbox.setAttribute('value', file.id)
    checkbox.setAttribute('checked', 'checked')
    checkbox.setAttribute('hidden', 'hidden')

    file.previewElement.appendChild(checkbox)
  }

  async _fetchFiles() {
    const postId = this.postId
    const response = await fetch(`/api/v1/posts/${postId}/attachments`,{
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
      method: 'get'
    })
    const json = await response.json()
    return json
  }

  async recommend_hashtag(files) {
    var formData = new FormData();
    files.forEach(file => {
      formData.append('images[]', file.dataURL);
    });
    const response = await fetch('/api/v1/posts/recommend_hashtags', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    })
    const json = await response.json()
    return json
  }
  
  isImage(type) {
    return /image\/(jpeg|png|jpg|gif)/.test(type)
  }

  isAiImage(type) {
    return /image\/(jpeg|png|jpg)/.test(type)
  }

  _createDropZone(){
    const self = this

    if (self.type.match(/image$/i)) {
      new Sortable(this.$dom, {
        animation: 150,
        swapThreshold: 1,
      });
    }

    return new Dropzone(this.$dom, {
      ...this._dropzoneSettings(),

      init: async function() {
        var myDropzone = this
        let isDragger = false
        let has_recommend_hashtag = false

        if (self.isEditForm) {
          // get existing files data
          const data = await self._fetchFiles()
          var data_array = []

          if (data['files'] == null && data['images'] == null) {
            return
          }

          if (self.type.match(/image$/i)) {
            data_array = data['images']
          } else {
            data_array = data['files']
          }

          // display existing files
          const filter_data_array = data_array.filter( a => String(a.file_group_id) === this.options.file_group_id )

          if (filter_data_array.length > 0){
            self.hint.classList.add('hidden')
          }

          filter_data_array.forEach( file => {
            var file_path = file.url
            myDropzone.files.push(file)
            myDropzone.options.addedfile.call(myDropzone, file)
            myDropzone.options.processing.call(myDropzone, file)
            if (self.type.match(/image$/i)) {
              myDropzone.options.thumbnail.call(myDropzone, file, file_path)
            }
            myDropzone.options.complete.call(myDropzone, file)

            self._createSortedInput({ self: self, file: file })
          })

          // change accepted file types
          const fileTypeEleName = self.$select.id.replace('draft_file_ids', 'file_type')
          const fileTypeSelect = document.getElementById(fileTypeEleName)

          fileTypeSelect.addEventListener('change', function(){
            // view _file_group_fields
            const displayTypes = this.options[this.selectedIndex].dataset['display'];
            // dropzone_conponent.html
            const hintFormats = self.hint.querySelector('.avaliable-formats')
            hintFormats.dataset['display'] = displayTypes
            hintFormats.innerHTML = i18next.t('models.base_form.available_formats') + ' : ' + displayTypes

            const displayCtbTip = this.options[this.selectedIndex].dataset['ctbTip'];
            const ctbTip = self.hint.querySelector('.ctb-tip')

            if (displayCtbTip == 'true') {
              ctbTip.classList.remove('hidden')
            } else {
              ctbTip.classList.add('hidden')
            }

            // addedfiles 不能使用 this.options.acceptedFiles 取得驗證格式
            self.acceptFormat = this.options[this.selectedIndex].dataset['formats'];
            myDropzone.options.acceptedFiles = self.acceptFormat;

            // ios file input accept 有bug當限制多種file input會全部都無法上傳
            if(isIOS()) {
              myDropzone.hiddenFileInput.accept = '';
            } else {
              myDropzone.hiddenFileInput.accept = self.acceptFormat;
            }
          })

          if (self.disabled == 'true') {
            myDropzone.disable()
          }
        }

        this.on("sending", function(file, xhr, formData) {

          if(self.isImage(file.type)) {
            var _send = xhr.send;
            xhr.send = function() {
              myDropzone.createThumbnail(
                file,
                myDropzone.options.thumbnailWidth,
                myDropzone.options.thumbnailHeight,
                myDropzone.options.thumbnailMethod,
                false,
                function(dataURL) {
                  // Update the Dropzone file thumbnail
                  myDropzone.emit('thumbnail', file, dataURL);
                }
              );
              _send.call(xhr, file);
            }
          } else {
            var _send = xhr.send;
            xhr.send = function() {
              _send.call(xhr, file);
            }
          }
        });

        this.on('successmultiple', function(files, response) {
          const fileIDs = response.fileIDs || this.options.fileIDs

          if(fileIDs) {
            const existingOptionIds = Array.from(self.$select.querySelectorAll('option')).map(node => node.value)
            fileIDs.forEach(id => {
              if (!existingOptionIds.includes(String(id))) {
                const fileOption = self._createOption({
                  value: id,
                  selected: 'selected'
                })
                self.$select.appendChild(fileOption)
              }
            })
          }
        })

        this.on("processing", function(file) {
          if(file.presignedUrl) {
            this.options.headers['Content-Type'] = file.type
            this.options.url = file.presignedUrl;
          }
          if(file.id) {
            this.options.fileIDs.push(file.id)
            self._createSortedInput({ self: self, file: file })
          }

          document.body.dataset.dropzoneUploading = true
        });

        this.on('success', function(file, response) {
          const fileID = file.id

          if(fileID) {
            const existingOptionIds = Array.from(self.$select.querySelectorAll('option')).map(node => node.value)

            if (!existingOptionIds.includes(String(fileID))) {
              const fileOption = self._createOption({
                value: fileID,
                selected: 'selected'
              })
              self.$select.appendChild(fileOption)
            }
          }

          uploadFileSuccess(file.name, file.id, self.$form, async () => {
            checkUploadState()
            // 圖片 ai suggest tags
            // 除了gif以外的圖片都會上傳給ai做辨識, 等到所有非gif圖片上傳成功後才會執行
            const upload_ai_images = myDropzone.files.filter(f => self.isAiImage(f.type))
            const upload_successed_ai_images = myDropzone.files.filter(f => self.isAiImage(f.type) && f.status === 'success')

            if(self.type === 'postImage' && !has_recommend_hashtag && upload_ai_images.length === upload_successed_ai_images.length) {
              has_recommend_hashtag = true
              try {
                const { tags } = await self.recommend_hashtag(upload_ai_images)

                // get tag list tomselect instance
                const tagListElement = document.querySelector('[name="post[tag_list]"]').tomselect
                if (tagListElement) {
                  tags.forEach(tag => {
                    // ai suggest tag style
                    tagListElement.addOption({ value: tag, text: tag, style: 'ai' })
                    tagListElement.addItem(tag)
                  })
                }
              } catch (err) {
                Sentry.captureException(`Ai tag api error: ${err}`);
              }
            }
          })
          
          if (self.errorMsg){
            self.errorMsg.classList.add('hidden')
          }
        });

        this.on('error', function(file, errorMessage){
          console.error(errorMessage)
          alert(errorMessage)
        })

        this.on("dragenter", function() {
          if (!isDragger) {
            myDropzone.element.classList.add("dz-drag-hover");
            isDragger = true;
          }
        });

        this.on("dragleave", function() {
          if (isDragger) {
            myDropzone.element.classList.remove("dz-drag-hover");
            isDragger = false; 
          }
        });

        this.on("drop", function() {
          if (isDragger) {
            myDropzone.element.classList.remove("dz-drag-hover");
            isDragger = false; 
          }
        });
      },

      addedfiles: function(files) {
        if(this.files.length <= self.settings.maxFiles) {

          if (this.files.length > 0){
            self.hint.classList.add('hidden')
          }
          files.forEach(file => {
            var fileName = file.name
            var fileExtension = fileName.split('.').pop().toLowerCase();

            // 除了html input accept外，這邊也會檢查檔案格式
            if (self.acceptFormat.indexOf(fileExtension) === -1){
              alert(`Only accept ${self.acceptFormat} file`)
              return 
            }
            getUploadLink(this, file, self.postId, (data) => {
              file.presignedUrl = data.presignedUrl
              file.id = data.id
              this.enqueueFile(file)
            })
          })
        } else {
          alert(`Exceed max files: ${self.settings.maxFiles}`)
          files.forEach(file => {
            this.removeFile(file)
          })
        }
      },

      removedfile: function(file) {
        //remove dropzone previewElement
        var previewDropzone = file.previewElement
        previewDropzone.parentNode.removeChild(file.previewElement)

        if (this.files.length < 1){
          self.hint.classList.remove('hidden')
        }

        // 判斷是 show 回傳的 json 還是 dropzone 產生的
        if (file.id == null && file.xhr == null) { return }

        if (file.id){
          var fileId = file.id
        }else{
          var fileId = JSON.parse(file.xhr.responseText).fileIDs[0]
        }

        removeLink(file.name, file.id, self.$form)

        // remove select option
        const option = self.$select.querySelector(`option[value="${fileId}"]`)
        if (option) {
          option.remove()
        }

        const idx = this.options.fileIDs.indexOf(fileId)
        if (idx > -1) {
          this.options.fileIDs.splice(idx, 1)
        }
      },
    })
  }
}

let instances = []

export const initDropZone = () => {
  document.querySelectorAll(DROPZONE_SELECTOR).forEach(($dom) => {
    instances.push(new DropZoneClass($dom))
  })
  // select form dropzone item
  // #post_image_ids
  // #post_file_ids (簡易上傳 models/_form.html.erb)
  // #post_draft_file_ids (進階上傳 models/_file_group_fields.html.erb)
  document.querySelectorAll('#post_image_ids > option, #post_file_ids > option, #post_draft_file_ids > option').forEach(dom => {
    dom.setAttribute('selected', 'selected')
  })
}

export const destroyDropZone = () => {
  instances.forEach((instance) => {
    instance.destroy()
  })
}
