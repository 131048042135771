import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import * as Sentry from "@sentry/browser";

export const initVariantSortable = () => {
  const postVariantForm = document.getElementById('post-variant-sortable');

  if (postVariantForm == undefined) {
    return
  }

  new Sortable(postVariantForm, {
    animation: 150,
    ghostClass: 'bg-grey-7',
    filter: '.disabled',
    onUpdate: function (e) {
      reorderVariantPosition()
    }
  });
}

export const reorderVariantPosition = () => {
  const postVariantForm = document.getElementById('post-variant-sortable');

  if (postVariantForm == undefined) {
    return
  }

  const postVariantItems = postVariantForm.querySelectorAll('.nested-fields');

  if (postVariantItems == undefined) {
    return
  }

  for(let i = 0; i < postVariantItems.length; i++) {
    const variantPosition = postVariantItems[i].querySelector('input.variant-position')
    variantPosition.value = i + 1
  }
}