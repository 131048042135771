const SCROLL = '[data-scroll]'
const scrollToTop = () => {
  const $dom = document.querySelector(SCROLL)
  if (!$dom) {
    return
  }

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      $dom.classList.remove("cursor-default");
      $dom.style.opacity = 1;
    } else {
      $dom.classList.add("cursor-default");
      $dom.style.opacity = 0;
    }
  }

  $dom.addEventListener('click', () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  })
}

export default scrollToTop
