const likePost = () => {
  const likeForms = document.querySelectorAll(".post_like");

  likeForms.forEach((element) => {
    element.addEventListener("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      if (data['status'] === 'saved'){
        element.classList.add('focus')
      } else {
        element.classList.remove('focus')
      }

      var likedCountEl = element.parentElement.querySelector('.post-liked-count')
      var likedCount   = parseInt(data['count'])

      if(likedCountEl && likedCount) {
        likedCountEl.innerText = likedCount;
      }
    });
  });
}

export { likePost };
