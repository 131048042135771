import i18next from 'i18next';

const SELECTOR = '[data-translate]'
const CONTENT_SELECTOR = '[data-translate-content]'
const BTN_SELECTOR = '[data-translate-content-btn]'

class Translate {
  constructor(translateElement) {
    this.translateElement = translateElement
    this.contentElement = this.translateElement.querySelector(CONTENT_SELECTOR)
    this.btnElement = this.translateElement.querySelector(BTN_SELECTOR)

    this.content = {
      original: this.contentElement.textContent,
      translated: '',
      state: 'original'
    }

    this.init()
  }

  init() {
    const browserLanguage = navigator.language.split('-')[0];
    // 非英文語系顯示翻譯按鈕
    if (browserLanguage !== 'en') {
      this.btnElement.style.display = 'block';
    }
    this.btnElement.addEventListener('click', this.changeContent.bind(this))
  }

  async translate() {
    // 有翻譯過就不再翻譯
    if (this.content.translated != '') return;
    
    try {
      const api_path = '/api/v1/translates'
      const browserLocale = navigator.language;
      const response = await fetch(api_path, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_locale: browserLocale,
          content: this.content.original
        })
      });
      
      if (response.ok) {
        const data = await response.json();
        this.content.translated = data['translate']
      }
    } catch (error) {
      console.error('translate', error)
    }
  }

  async changeContent() {
    // content state
    if (this.content.state == 'original') {
      this.content.state = 'translated'
      await this.translate()
      this.contentElement.textContent = this.content.translated
      this.btnElement.textContent = i18next.t('comment_form.original_btn')
    } else {
      this.content.state = 'original'
      this.contentElement.textContent = this.content.original
      this.btnElement.textContent = i18next.t('comment_form.translate_btn')
    }
  }
}

let instances = []

export const initTranslateContent = () => {
  document.querySelectorAll(SELECTOR).forEach((translateElement) => {
    instances.push(new Translate(translateElement))
  })
}