import { Controller } from "stimulus"
import { format} from 'date-fns'

export default class extends Controller {
  static targets = ['closeBtn']
  currentAnnounceInfo = null
  oldAnnounceInfo = null
  connect() {
    // localStorage format
    // {
    //   close: true,
    //   id: "19",
    //   timestamp: 1721113287123,
    //   updatedAt: "2024-07-16",
    //   closeTimes: 0
    // }

    this.currentAnnounceInfo = {
      id: this.element.dataset.id,
      updatedAt: format(new Date(this.element.dataset.updatedAt), 'yyyy-MM-dd'),
      timestamp: new Date().getTime(),
    }
    this.oldAnnounceInfo = JSON.parse(localStorage.getItem('announce'))
    // 公告有更新或不同公告會顯示
    if (!this.oldAnnounceInfo || (this.oldAnnounceInfo && (this.oldAnnounceInfo.id !== this.currentAnnounceInfo.id || this.oldAnnounceInfo.updatedAt !== this.currentAnnounceInfo.updatedAt))) {
      this.oldAnnounceInfo = {...this.currentAnnounceInfo, closed: false, closeTimes: 0}
      localStorage.setItem('announce', JSON.stringify(this.oldAnnounceInfo))
      this.show()
    }
    // 七天後再次顯示 86400 * 1000 * 7
    if (this.oldAnnounceInfo &&
        (parseInt(this.oldAnnounceInfo.timestamp + 86400 * 1000 * 7) <= parseInt(this.currentAnnounceInfo.timestamp)) &&
        this.oldAnnounceInfo.closeTimes < 2
      ) {
      this.oldAnnounceInfo = {...this.currentAnnounceInfo, closed: false, closeTimes: this.oldAnnounceInfo.closeTimes}
      localStorage.setItem('announce', JSON.stringify(this.oldAnnounceInfo))
      this.show()
    }

    if (this.oldAnnounceInfo && !this.oldAnnounceInfo.closed ) {
      this.show()
    }
  }
  show() {
    this.element.classList.remove('hidden')
  }
  hide() {
    this.element.classList.add('hidden')
    localStorage.setItem('announce', JSON.stringify({
      ...this.oldAnnounceInfo,
      closed: true,
      closeTimes: this.oldAnnounceInfo.closeTimes + 1
    }))
  }
}

