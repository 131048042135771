class FooterAnimation {
  constructor(footer, showPx, hidePx, delay) {
    this.footer = footer;
    this.showPx = showPx;
    this.hidePx = hidePx;
    this.delay = delay;
    this.lastScrollTop = 0;
    this.timer = null;
    this.isScrollingDown = false;

    this.init(footer);
  }

  init(footer) {
    footer.classList.add('hidden-footer');
    this.bindEvents();
  }

  bindEvents() {
    window.addEventListener('scroll', this.debounce(() => {
      clearTimeout(this.timer);
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // 檢查滾動方向
      if (scrollTop > this.lastScrollTop) {
        this.isScrollingDown = true;
      } else {
        this.isScrollingDown = false;
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // 防止滾動過程中負值

      // 設置一個新的計時器來檢查滾動停止後的一段時間
      this.timer = setTimeout(() => this.checkScroll(), this.delay);
    },300));
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  checkScroll() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (this.isScrollingDown) {
      // 向下滾動
      if (scrollTop > this.showPx) {
        if (!this.footer.classList.contains('visible-footer')) {
          this.footer.classList.remove('hidden-footer');
          this.footer.classList.add('visible-footer');
        }
      }
    } else {
      // 向上滾動
      if (scrollTop < this.hidePx) {
        if (this.footer.classList.contains('visible-footer')) {
          this.footer.classList.add('hidden-footer');
          this.footer.classList.remove('visible-footer');
        }
      }
    }
  }
}

export const initFooterAnimation = (selector, showPx, hidePx, delay) => {
  const footer = document.querySelector(selector);
  if (footer) {
    new FooterAnimation(footer, showPx, hidePx, delay);
  }
}
